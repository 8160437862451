<template>
    <div class="  is-fullheight sticky-sidebar" >
      <aside class="menu column  aside   is-fullheight" >
       <p class="menu-label">
        Members
      </p>
       <ul class="menu-list">
         <li>
           <a class="">General</a>
           <ul>
             <li @click="activeMenuLink = '/members'"><router-link to="/members"  :class="{'is-active':activeMenuLink === '/members'}">Dashboard</router-link></li>
             <li @click="activeMenuLink = '/members-by-grade'"><router-link to="/members-by-grade"  :class="{'is-active':activeMenuLink === '/members-by-grade'}">Members By Grade</router-link></li>
             <li @click="activeMenuLink = '/members/add'" style="display:none"><router-link to="/members/add"  :class="{'is-active':activeMenuLink === '/members/add'}">Add Member</router-link></li>
             <li @click="activeMenuLink = '/members/nmc-check'"><router-link to="/members/nmc-check"  :class="{'is-active':activeMenuLink === '/members/nmc-check'}">NMC Check</router-link></li>
             <li @click="activeMenuLink = '/members/hcpc-check'"><router-link to="/members/hcpc-check"  :class="{'is-active':activeMenuLink === '/members/hcpc-check'}">HCPC Check</router-link></li>
            
           </ul>
         </li>
         <li style="display:none">
           <a class="">General</a>
           <ul>
             <li @click="activeMenuLink = '/members/alerts'"><router-link to="/members/alerts"  :class="{'is-active':activeMenuLink === '/members/alerts'}">View Alerts</router-link></li>
             <li @click="activeMenuLink = '/members/add-alert'"><router-link to="/members/add-alert"  :class="{'is-active':activeMenuLink === '/members/add-alert'}">Add Alert</router-link></li>
           </ul>
         </li>

         <li>
           <a class="">Reports</a>
           <ul>
            <li @click="activeMenuLink = '/members/visa-report'"><router-link to="/members/visa-report"  :class="{'is-active':activeMenuLink === '/members/visa-report'}">Visa Report</router-link></li> 
            <li @click="activeMenuLink = '/members/nmc-report'"><router-link to="/members/nmc-report"  :class="{'is-active':activeMenuLink === '/members/nmc-report'}">NMC Report</router-link></li> 
            <li @click="activeMenuLink = '/members/niscc-report'"><router-link to="/members/niscc-report"  :class="{'is-active':activeMenuLink === '/members/niscc-report'}">NISCC Report</router-link></li> 
            <li @click="activeMenuLink = '/members/hcpc-report'"><router-link to="/members/hcpc-report"  :class="{'is-active':activeMenuLink === '/members/hcpc-report'}">HCPC Report</router-link></li> 
            <li @click="activeMenuLink = '/members/cop-report'"><router-link to="/members/cop-report"  :class="{'is-active':activeMenuLink === '/members/cop-report'}">CoP Report</router-link></li> 
            <li @click="activeMenuLink = '/members/access-ni-required'" style="display:none"><router-link to="/members/access-ni-required"  :class="{'is-active':activeMenuLink === '/members/access-ni-required'}">Access NI Expired</router-link></li>
             <li @click="activeMenuLink = '/members/training-report'" style=""><router-link to="/members/training-report"  :class="{'is-active':activeMenuLink === '/members/training-report'}">Training Report</router-link></li>
             <li @click="activeMenuLink = '/members/last-worked-report'"><router-link to="/members/last-worked-report"  :class="{'is-active':activeMenuLink === '/members/last-worked-report'}">Last Worked Report</router-link></li>
             <li @click="activeMenuLink = '/members/cos-report'"><router-link to="/members/cos-report"  :class="{'is-active':activeMenuLink === '/members/cos-report'}">CoS Report</router-link></li>
             <li @click="activeMenuLink = '/members/eligibility-form-report'"><router-link to="/members/eligibility-form-report"  :class="{'is-active':activeMenuLink === '/members/eligibility-form-report'}">Eligibility Form Report</router-link></li>
             <li @click="activeMenuLink = '/members/20-hours-report'"><router-link to="/members/20-hours-report"  :class="{'is-active':activeMenuLink === '/members/20-hours-report'}">20 Hours Check</router-link></li>
             <li @click="activeMenuLink = '/members/list-grades'"><router-link to="/members/list-grades"  :class="{'is-active':activeMenuLink === '/members/list-grades'}">List Grades</router-link></li>
             
           </ul>
         </li>
         
         
         
       </ul>
       
     </aside>
     
    </div>
 </template>
 
 <script>
 export default {
   name: 'SideBarMembers',
   components: {
   },
   data() {
     return { activeMenuLink: null }
   },
   mounted() {
     //this.activeMenuLink = this.$route.path.substring(1)
     this.activeMenuLink = this.$route.path
     console.log(this.activeMenuLink)
   }
 }
 
 </script>