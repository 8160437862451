<template>
<nav class="navbar has-background-info-dark  is-fixed-top " role="navigation" aria-label="main navigation">
  <div class="navbar-brand  ">
    <a href="/" class="navbar-item mr-6" >
      <img src="@/assets/images/hw4-logo.png" />
    </a>

    <a href="/bookings/new#pending"><button class="button is-danger m-1" v-if="app_replies.length > 0">{{ app_replies.length }} App Replies</button></a>
    


  </div>
  <div class="buttons  navbar-end">
    <a href="/on-call" style="display:none"><button class="button is-info m-1 mr-4">On Call</button></a>  
    <a href="/members"><button class="button is-success m-1">Members</button></a>
      <a href="/bookings/new"><button class="button is-warning m-1">Bookings</button></a>
      <a href="/applications/dashboard-new"><button class="button is-light m-1">Applications</button></a>
      
    </div>

 
</nav>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';

export default {
  name: 'NavBar',
  components: {
    
  },
  data: function () {
        return {
                API_NAME: process.env.VUE_APP_API_NAME,
                accessToken: '',
                user: [],
                loading: true,
                app_replies: 0
               
        }
    },
  methods: {
          logout() {
              if(confirm('Are you sure you want to logout ?'))
              {
                  this.$auth.logout({
                  returnTo: window.location.origin
                });
              }
      },
      async getAppReplies() {
          

          UtilityService.getSimpleApiData(this.accessToken, "member_alerts?interested=1&assigned=0")
                .then(
                    (app_replies => {
                        this.$set(this, "app_replies", app_replies);
                        
                    }).bind(this)
                );
          
      },
    },
    mounted() {
          this.getAccessToken()
            .then(() => { 
                this.getAppReplies()

                setInterval(() => {
                  this.getAppReplies()
                }, 90000);
            });
          
        },
}
</script>